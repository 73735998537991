<template>
  <LibraryMain />
</template>

<script>
import LibraryMain from '@/components/library/LibraryMain';

export default {
  name: 'Library',
  components: {
    LibraryMain
  }
}
</script>

<style>

</style>