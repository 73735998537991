<template>
  <section class="block library general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </section>
</template>

<script>
import Axios from 'axios';
import CommonTable from '../common/CommonTable';
import CommonTitle from '../common/CommonTitle';

export default {
  name: 'LibraryMain',
  data() {
    return {
      options_title: {
        name: 'Фонотека',
        id: 'library',
        actions: '',
      },
      options_table: {
        id: 'library',
        request: 'library',
        actions: 'edit-remove',
        ths: [
          { id: 'name', name: 'Название', width: '20%', value: '', sort: true, search: true },
          { id: 'executor_id', name: 'Исполнитель', width: '20%', value: '', sort: true, search: true },
          { id: 'type', name: 'Тип', width: '8%', value: '', sort: true, search: true },
          { id: 'duration', name: 'Длительность (сек.)', width: '18%', value: '', sort: true, search: true },
          { id: 'status_id', name: 'Статус', width: '17%', value: '', sort: true, search: true },
          { id: 'order', name: 'Порядок', width: '11%', value: '', sort: true, search: true },
          { id: '', name: '', width: '6%', value: '', sort: false, search: false },
        ],
        table: {
          variables: ['name', 'executor_id', 'type', 'duration', 'status_id', 'order'],
          page: 0,
          limit: 10,
          total: 0,
          data: null
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('createLibrary', (result) => {
      this.options_table.table.data.push(result.data);
    });
    this.$bus.$on('editLibrary', (result) => {
      const library = this.options_table.table.data.find(el => el._id === result.data._id);
      const props = ['name', 'duration', 'order', 'status_id', 'type', 'executor_id'];
      if (library) props.forEach(prop => library[prop] = result.data[prop]);
    });
    this.$bus.$on('removeLibrary', (result) => {
      this.options_table.table.data = this.options_table.table.data.filter(el => el._id !== result.data);
    });
    Axios
      .get(`/api/library`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          limit: 10,
          page: 0
        }
      })
      .then(res => {
        this.options_table.table.data = res.data.data;
        this.options_table.table.page = 0;
        this.options_table.table.limit = 10;
        this.options_table.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      })
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style>
.library {
  display: flex;
  flex-direction: column;
}
</style>